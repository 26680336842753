import firebase from 'firebase'

export const googleLogin = () => {
	const provider = new firebase.auth.GoogleAuthProvider()
	firebase
		.auth()
		.signInWithPopup(provider)
		.then((res) => {
			console.log('yeet', res, res.user)
		})
}
