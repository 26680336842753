import React from 'react'
import { useHistory } from 'react-router-dom'
import { useStore as useAppStore } from 'State/app'
import { v4 as uuidv4 } from 'uuid'
import {
	Button,
	Select,
	InputLabel,
	MenuItem,
	Card,
	CardHeader,
	Avatar,
	CardContent,
	CardActions,
	Box,
	Typography,
	IconButton,
	Collapse,
	Paper,
	Fab,
	FormControl,
	Modal,
	Zoom,
	Container,
	Grid,
	makeStyles,
	Menu,
} from '@material-ui/core'
import FavoriteIcon from '@material-ui/icons/Favorite'
import ShareIcon from '@material-ui/icons/Share'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import MoreVertIcon from '@material-ui/icons/MoreVert'
import AddIcon from '@material-ui/icons/Add'

import movements from 'Assets/data/movements'
import AlGore from 'Assets/img/Al-gore.png'
import SimpleSet from 'Cogs/SimpleSet'
import {createResource} from 'Util/suspense'
import { getUserWorkoutCollection, createWorkout, getUserWorkoutCollectionByTime, deleteWorkout } from 'Util/db'
import firebase from 'firebase'
import { useAuthState } from 'react-firebase-hooks/auth'


const Temp = () => <div>TEMP</div>

const WorkoutHistory = () => {
	const [state, dispatch] = useAppStore()
	const sorted = workoutCollectionToArray( state.workouts )

	return (
		<div className="workout-history">
			{sorted.map((workout, i) => (
				<Workout
					key={workout.id}
					{...workout}
					appStore={[state, dispatch]}
				/>
			))}
		</div>
	)
}

const Workout = ({ id, exercises, appStore, timestamp, length }) => {
	const [user] = useAuthState(firebase.auth())
	const [state, dispatch] = appStore
	const date = new Date(timestamp)
	const history = useHistory()

	// let title = 'Workout : '
	let title = Intl.DateTimeFormat('en', { dateStyle: 'full' }).format(date)

	const [anchorEl, setAnchorEl] = React.useState(null)
	const [tryDelete, setTryDelete] = React.useState(null)
	const open = Boolean(anchorEl)

	const handleClick = (event) => {
		setAnchorEl(event.currentTarget)
	}

	const handleClose = () => {
		setAnchorEl(null)
	}

	///

	//

	const options = {
		Edit: () => history.push( '/workout/edit/' + id ),
		Copy: () => copyNewWorkout( user.uid, exercises, history ),
		Delete: () => setTimeout(() => setTryDelete(true), 1),
	}

	if (tryDelete) {
		return (
			<div>
				<Modal open={true} onClose={() => setTryDelete(false)}>
					<div className="modal__base modal__confirm">
						<div className="img">
							<img src={AlGore} />
						</div>
						<span>
							Do you super cereally want to delete this workout?
						</span>
						<div className="buttons">
							<Button
								onClick={async () => {
									const test = deleteWorkout( user.uid, id )
									dispatch({
										type: 'deleteWorkout',
										payload: id,
									})
									setTryDelete(false)
								}}
							>
								Yes
							</Button>
							<Button
								onClick={() => {
									setTryDelete(false)
								}}
							>
								No
							</Button>
						</div>
					</div>
				</Modal>
			</div>
		)
	}

	return (
		<Box className="workout-entry" mb={3}>
			<Card mb={3}>
				<CardHeader
					className="card-header-underline"
					action={
						<div>
							<IconButton
								aria-label="more"
								aria-controls="long-menu"
								aria-haspopup="true"
								onClick={handleClick}
							>
								<MoreVertIcon />
							</IconButton>
							<Menu
								id="long-menu"
								anchorEl={anchorEl}
								open={open}
								onClose={handleClose}
							>
								{Object.entries(options).map(
									([text, action]) => (
										<MenuItem
											key={'workout-menu-' + text}
											onClick={action}
										>
											{text}
										</MenuItem>
									)
								)}
							</Menu>
						</div>
					}
					title={title}
					subheader={Math.round(length / 60 / 10) / 100 + ' minutes'}
				/>
				<div className="workout-entry__exercises">
					{exercises.map((exercise, i) => (
						<Exercise key={i} {...exercise} />
					))}
				</div>
			</Card>
		</Box>
	)
}

const copyNewWorkout = async ( uid, exercises, history ) => {
	const id = '0-' + uuidv4()



	await createWorkout(uid,{
			id,
			timestamp: firebase.firestore.Timestamp.now().seconds*1000,
			exercises: exercises.map( exercise => ({
				...exercise,
				sets: exercise.sets.map( set => ({
					...set,
					reps: null
				}))
			})),
			length: false,
		})

	history.push( '/workout/edit/' + id )
}

const Exercise = ({ movementID, sets, updateSets }) => {
	return (
		<Box className="workout-entry__exercise" mb={3}>
			<div>
				{/*Movement: */}
				{movements.find(({ id }) => id === movementID).name}
			</div>
			<div className="set-list">
				{sets.map(({ reps }, i) => (
					<SimpleSet
						key={i}
						targetReps={8}
						reps={reps}
						update={() => {}}
					/>
				))}
			</div>
		</Box>
	)
}

const workoutCollectionToArray = collection => {
	const workouts = Object.values( collection )

	workouts.sort( ( a, b ) => b.timestamp - a.timestamp )

	return workouts
}

export default WorkoutHistory
