import React from 'react'
import { useStore } from 'State/app'
import {getUserWorkoutCollection} from 'Util/db'
import * as test from 'react-router-dom'
import firebase from 'firebase'
import { useAuthState } from 'react-firebase-hooks/auth'

const Load = ({ children = null }) => {
	const [user] = useAuthState(firebase.auth())
	let fffff = test.useLocation()
	const [load, setLoad] = React.useState(false)
	const [state, dispatch] = useStore()

	React.useEffect(() => {
		if ( ! user ) return
		setLoad( false )

		const doit = async () => {
			let userUpToDate = false

			if ( ! userUpToDate ) {
			    const collection = await getUserWorkoutCollection( user.uid )
			    const mapped = shardedCollectionToMap(collection)

			    dispatch({
			      type: 'setWorkouts',
			      payload: mapped,
			    })
			}

			setLoad( true )
		}

		doit()
	}, [ user, fffff.pathname ] )

	if ( ! load ) return <div>Loading . . .</div>

	return children
}

const shardedCollectionToMap = collectionArray => {
	const map = {}

	collectionArray.forEach( idMap => {
		Object.entries( idMap ).forEach( ([ key, data ]) => {
			map[ key ] = data
		})
	})

	return map
}

export default Load
