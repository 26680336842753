import React, { useState } from 'react'
import {
	Button,
	Select,
	InputLabel,
	MenuItem,
	Card,
	CardHeader,
	Avatar,
	CardContent,
	CardActions,
	Box,
	Typography,
	IconButton,
	Collapse,
	Paper,
	Fab,
	FormControl,
	Modal,
	Zoom,
	Container,
	Grid,
	makeStyles,
} from '@material-ui/core'
import FavoriteIcon from '@material-ui/icons/Favorite'
import ShareIcon from '@material-ui/icons/Share'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import MoreVertIcon from '@material-ui/icons/MoreVert'
import AddIcon from '@material-ui/icons/Add'

import movements from 'Assets/data/movements'
import AddExercise from 'Cogs/AddExercise'
import SimpleSet from 'Cogs/SimpleSet'
import { useStore } from 'State/app'

const useStyles = makeStyles((theme) => ({
	fab: {
		position: 'absolute',
		bottom: theme.spacing(2),
		right: theme.spacing(2),
	},
}))


/*

user edits workout 1

clicks to overview

clicks to edit workout 2, to view previous stats



*/
const WorkoutNew = () => {
	// const [state,dispatch] = useStore()
	const classes = useStyles()
	const [openAddNew, setOpenAddNew] = useState(false)
	const [state, setState] = useState({
		timestamp: Date.now(),
		exercises: [],
		length: false,
	})
	const update = (o) => setState({ ...state, ...o })
	const onAddExercise = ({ movementID }) =>
		update({
			exercises: [
				...state.exercises,
				{ type: 'standard', movementID, sets: [] },
			],
		})

	const transitionDuration = {
		enter: 350,
		exit: 350,
	}

	return (
		<div id="workout-new">
			{state.exercises.map((e, i) => (
				<Exercise {...e} key={i} />
			))}

			<Button onClick={() => {}}>End Workout</Button>

			<Modal
				open={openAddNew}
				onClose={() => setOpenAddNew(false)}
				aria-labelledby="simple-modal-title"
				aria-describedby="simple-modal-description"
			>
				<Container className="layout__center" maxWidth="sm">
					<AddExercise onAdd={onAddExercise} />
				</Container>
			</Modal>

			<Zoom
				key={'yefdsfet'}
				in={true}
				timeout={transitionDuration}
				style={{
					transitionDelay: `${transitionDuration.exit}ms`,
				}}
				unmountOnExit
			>
				<Fab
					className={classes.fab}
					onClick={() => setOpenAddNew(true)}
					variant="extended"
					color="primary"
					aria-label="add"
				>
					<AddIcon />
					Add Exercise
				</Fab>
			</Zoom>
		</div>
	)
}

const Exercise = ({ movementID }) => {
	const [sets, setSets] = React.useState(Array(3).fill({ reps: null }))
	const updateSet = (index) => (reps) => {
		let newSets = [...sets]

		newSets[index] = { ...sets[index], reps: reps }

		setSets(newSets)
	}

	return (
		<Box mb={3}>
			<Card mb={3}>
				<CardHeader
					className="card-header-underline"
					action={
						<IconButton aria-label="settings">
							<MoreVertIcon />
						</IconButton>
					}
					title={
						'Movement: ' +
						movements.find(({ id }) => movementID === id).name
					}
				/>
				<CardContent>
					<div className="set-list">
						{sets.map(({ reps }, i) => (
							<SimpleSet
								key={i}
								targetReps={8}
								reps={reps}
								update={updateSet(i)}
							/>
						))}
					</div>
				</CardContent>
			</Card>
		</Box>
	)
}

export default WorkoutNew
