import React from 'react'
import {
	Button,
	Typography,
} from '@material-ui/core'

const InputNumber = ({ label, onChange, value, max, min }) => {
	const update = count => {
		if ( min !== undefined ) count = Math.max( min, count )
		if ( max !== undefined ) count = Math.min( max, count )
		onChange( count )
	}

	return (
		<div>
			<Typography>{ label }</Typography>
			<Button onClick={() => update(value - 1)}>
				-
			</Button>
			<input
				value={value}
				onChange={(e) => update(+e.target.value)}
				type="number"
			/>
			<Button
				onClick={() => update(value + 1)}
			>
				+
			</Button>
		</div>
	)
}

export default InputNumber
