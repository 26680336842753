import React from 'react'

let deferredPrompt = false

console.log('poo')
window.addEventListener( 'beforeinstallprompt', e => {
	console.log('test??',e)
	deferredPrompt = e
} )

const useInstallPWA = hideInstallButton => {
	const [ installable, setInstallable ] = React.useState( Boolean( deferredPrompt ) )

	React.useEffect( () => {
		if ( deferredPrompt && ! installable ) {
			console.log('deferredPrompt',deferredPrompt)
			setInstallable( true )
		}
	}, [] )

	window.addEventListener( 'beforeinstallprompt', e => {
		console.log('zzz??',e)
			setInstallable( true )
	} )

	console.log('yo')

	const install = () => {
		deferredPrompt.prompt()

		deferredPrompt.userChoice.then( choiceResult => {

			if ( 'accepted' === choiceResult.outcome ) {

				setInstallable( false )
				deferredPrompt = false

			}

		})
	}

	return [ installable, install ]
}

export default useInstallPWA
