import React from 'react'
import InputNumber from 'Widgets/InputNumber'
import {
	Modal,
} from '@material-ui/core'

////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////
import { useCallback, useRef, useState } from "react";

const useLongPress = (
    onLongPress,
    onClick,
    { shouldPreventDefault = true, delay = 300 } = {}
    ) => {
    const [longPressTriggered, setLongPressTriggered] = useState(false);
    const timeout = useRef();
    const target = useRef();

    const start = useCallback(
        event => {
            if (shouldPreventDefault && event.target) {
                    event.target.addEventListener("touchend", preventDefault, {
                    passive: false
                });
                target.current = event.target;
            }
            timeout.current = setTimeout(() => {
                onLongPress(event);
                setLongPressTriggered(true);
            }, delay);
        },
        [onLongPress, delay, shouldPreventDefault]
    );

    const clear = useCallback(
        (event, shouldTriggerClick = true) => {
            timeout.current && clearTimeout(timeout.current);
            shouldTriggerClick && !longPressTriggered && onClick();
            setLongPressTriggered(false);
            if (shouldPreventDefault && target.current) {
                target.current.removeEventListener("touchend", preventDefault);
            }
        },
        [shouldPreventDefault, onClick, longPressTriggered]
    );

    return {
        onMouseDown: e => start(e),
        onTouchStart: e => start(e),
        onMouseUp: e => clear(e),
        onMouseLeave: e => clear(e, false),
        onTouchEnd: e => clear(e)
    };
};

const isTouchEvent = event => {
return "touches" in event;
};

const preventDefault = event => {
if (!isTouchEvent(event)) return;

if (event.touches.length < 2 && event.preventDefault) {
    event.preventDefault();
}
};
////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////

const SimpleSet = ({ targetSets, reps, update }) => {
	const [modalOpen, setModalOpen] = React.useState(false)
	let classes = 'set'
	const onClick = () => {
		if (null === reps || isNaN( reps )) {
			update(targetSets.reps)
		} else if (0 === reps) {
			update(null)
		} else {
			update(reps - 1)
		}
	}

    const onLongPress = () => setModalOpen(true)
    const defaultOptions = {
        shouldPreventDefault: true,
        delay: 500,
    };
    const longPressEvent = useLongPress(onLongPress, onClick, defaultOptions);


	return (
		<div className={classes}>
			<span {...longPressEvent} className={ null === reps ? 'idle-set' : '' }>{reps}</span>

			<Modal open={modalOpen} onClose={() => setModalOpen(false)}>
				<div className="modal__base modal__confirm">
					<InputNumber
						label="Rep Count"
						value={reps || 0}
						onChange={ count => {
							console.log('SIMPLE reps',reps)

							update( count )
						}}
						min={ 0 }
					/>
				</div>
			</Modal>
		</div>
	)
}

export default SimpleSet
