import React from 'react'
import { useParams, useHistory } from 'react-router-dom'
import { setIn, updateIn, fromJS } from 'immutable'
import { AppState } from 'Types'
import workoutReducer from './workout'
import paramsReducer from './params'
import firebase from 'firebase'
import { useAuthState } from 'react-firebase-hooks/auth'
import { setWorkout } from 'Util/db'

const VERSION = 0.12

const autoSave = localStorage.getItem('autoSave')
const initialValues = {
	params: {},
	version: VERSION,
	workouts: {},
}
const loadData = JSON.parse( JSON.stringify( initialValues ) )
if ( autoSave ) {
	const data = JSON.parse( autoSave )

	if ( data.version >= VERSION ) {
		Object.assign( loadData, JSON.parse( autoSave ) )
	}
}

const stateContext = React.createContext({} as AppState)
const dispatchContext = React.createContext({} as any)
const actions = {
	setChangesMade: ( state, payload ) => setIn( state, ['changesMade'], payload ),
	setWorkouts: ( state, workouts ) => setIn(state, ['workouts'], workouts),
	clearUserData: ( state ) => ({...initialValues}),

	deleteWorkout: (state, workoutID) => {
		return updateIn(state, ['workouts'], (workouts) => Object.fromEntries( Object.entries( workouts ).filter( ([ id ]) => id !== workoutID ) ) )
	},




	//////////////////
	// Edit Workout //
	//////////////////
	updateWorkoutExercise: (state, { workoutID, exerciseIndex, exercise }) =>
		setIn(
			state,
			['workouts', workoutID, 'exercises', exerciseIndex],
			exercise
		),
	deleteWorkoutExercise: (state, { workoutID, exerciseIndex }) => {
		return updateIn(
			state,
			['workouts', workoutID, 'exercises'],
			(exercises) =>
				exercises.filter((_, j) => j !== exerciseIndex)
		)
	},
	updateWorkoutSets: (state, { workoutID, exerciseIndex, newSets }) =>
		updateIn(
			state,
			['workouts', workoutID, 'exercises', exerciseIndex],
			(exercise) => ({...exercise, sets: newSets})
		),
	addExercise: (state, { workoutID, exercise}) => {
		return updateIn(
			state,
			['workouts', workoutID, 'exercises'],
			(exercises) => [...exercises, exercise]
		)
	},
	endWorkout: (state, workoutID) =>
		setIn(
			state,
			['workouts', workoutID, 'length'],
			Date.now() - state.workouts[workoutID].timestamp
		),
}
const reducer = (state: AppState, action): AppState => {
	let res

	return (
		paramsReducer( state, action )
		|| workoutReducer( state, action )
		|| actions[action.type](state, action.payload)
		|| state
	)
}

function Provider(props) {
	const [user] = useAuthState(firebase.auth())
	const [state, dispatch] = React.useReducer(reducer, loadData)
	let params = useParams<any>()

	// track params for reducer
	React.useEffect(() => {
		dispatch({ type: 'params:set', params })
	}, [params])

	// local auto save
	React.useEffect(() => {
		localStorage.setItem('autoSave', JSON.stringify(state))
	}, [state])

	// cloud save
	React.useEffect(() => {
		const id = setTimeout( () => {
			if ( params.workoutID ) {
				setWorkout( user.uid, state.workouts[ params.workoutID ] )
			}
		}, 1000 )

		return () => clearTimeout( id )
	}, [ state.workouts ])

	return (
		<stateContext.Provider value={state}>
			<dispatchContext.Provider value={dispatch}>
				{props.children}
			</dispatchContext.Provider>
		</stateContext.Provider>
	)
}

const useState = () => React.useContext(stateContext)
const useDispatch = () => React.useContext(dispatchContext)
const useStore = () => [useState(), useDispatch()]

export { Provider, useStore, useState, useDispatch }
export default { Provider, useStore, useState, useDispatch }
