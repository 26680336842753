import React from 'react'
import { googleLogin } from 'Util/ssoLogin'

function App(a, b) {
	return (
		<div className="page__unauthenticated">
			Log in to get started

			<button onClick={googleLogin}>Click me to log in</button>
		</div>
	)
}

export default App
