import React from 'react'
import { setIn, updateIn, fromJS } from 'immutable'
import { AppState } from 'Types'

const reducer = (state: AppState, action): AppState => {
	const workout = state.workouts[ state.params.workoutID ]
	const workoutID = state.params.workoutID

	switch ( action.type ) {
		case 'workout:clearSet': {
			return updateIn(
				state,
				['workouts', workoutID, 'exercises', action.exerciseIndex, 'sets'],
				( sets ) => sets.map( set => ({
					...set,
					reps: null,
				}))
			)
		}
	}
}

export default reducer
