import React, { useState } from 'react'
import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import Typography from '@material-ui/core/Typography'
import { Link, useHistory } from 'react-router-dom'
import { makeStyles, useTheme } from '@material-ui/core/styles'
import Divider from '@material-ui/core/Divider'
import IconButton from '@material-ui/core/IconButton'
import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import List from '@material-ui/core/List'
import Drawer from '@material-ui/core/Drawer'
import Button from '@material-ui/core/Button'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'

import InboxIcon from '@material-ui/icons/MoveToInbox'
import MailIcon from '@material-ui/icons/Mail'
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft'
import ChevronRightIcon from '@material-ui/icons/ChevronRight'
import MenuIcon from '@material-ui/icons/Menu'
import SettingsIcon from '@material-ui/icons/Settings'
import NotificationsIcon from '@material-ui/icons/Notifications'
import AccountCircle from '@material-ui/icons/AccountCircle'
import { useStore } from 'State/app'
import useInstallPWA from 'Util/useInstallPWA'

import firebase from 'firebase'
import { useAuthState } from 'react-firebase-hooks/auth'
import GetAppIcon from '@material-ui/icons/GetApp';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import { googleLogin } from 'Util/ssoLogin'

const drawerWidth = 240

const useStyles = makeStyles((theme) => ({
	root: {
		display: 'flex',
	},
	appBar: {
		transition: theme.transitions.create(['margin', 'width'], {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.leavingScreen,
		}),
	},
	appBarShift: {
		width: `calc(100% - ${drawerWidth}px)`,
		marginLeft: drawerWidth,
		transition: theme.transitions.create(['margin', 'width'], {
			easing: theme.transitions.easing.easeOut,
			duration: theme.transitions.duration.enteringScreen,
		}),
	},
	menuButton: {
		marginRight: theme.spacing(2),
	},
	hide: {
		display: 'none',
	},
	drawer: {
		width: drawerWidth,
		flexShrink: 0,
	},
	drawerPaper: {
		width: drawerWidth,
	},
	drawerHeader: {
		display: 'flex',
		alignItems: 'center',
		padding: theme.spacing(0, 1),
		...theme.mixins.toolbar,
		justifyContent: 'flex-end',
	},
	content: {
		flexGrow: 1,
		padding: theme.spacing(3),
		transition: theme.transitions.create('margin', {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.leavingScreen,
		}),
		marginLeft: -drawerWidth,
	},
	contentShift: {
		transition: theme.transitions.create('margin', {
			easing: theme.transitions.easing.easeOut,
			duration: theme.transitions.duration.enteringScreen,
		}),
		marginLeft: 0,
	},
	title: {
		flexGrow: 1,
		color: 'inherit',
		textDecoration: 'none',
	},
}))

const IamAComponent = () => {
	const [ installable, install ] = useInstallPWA()
	const [state, dispatch] = useStore()
	const [user, loading, error] = useAuthState(firebase.auth())
	const classes = useStyles()
	const theme = useTheme()
	const [open, setOpen] = useState(false)
	const [anchorEl, setAnchorEl] = React.useState(null)
	const history = useHistory()

	const signOut = () => {
		dispatch({ type: 'clearUserData' })
		firebase.auth().signOut()
	}

	const menuId = 'primary-search-account-menu'
	let userStuff = (
		<Button onClick={googleLogin} color="inherit">
			Login
		</Button>
	)
	if (user) {
		userStuff = (
			<IconButton
				edge="end"
				aria-label="account of current user"
				aria-controls={menuId}
				aria-haspopup="true"
				onClick={(event) => {
					setAnchorEl(event.currentTarget)
				}}
				color="inherit"
			>
				<AccountCircle />
			</IconButton>
		)
	}

	const accountMenu = (
		<Menu
			key="appbar-user-menu"
			anchorEl={anchorEl}
			anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
			id={menuId}
			keepMounted
			transformOrigin={{ vertical: 'top', horizontal: 'right' }}
			open={Boolean(anchorEl)}
			onClose={() => setAnchorEl(null)}
		>
			<MenuItem onClick={() => setAnchorEl(null)}>Profile</MenuItem>
			<MenuItem onClick={() => setAnchorEl(null)}>My account</MenuItem>
		</Menu>
	)

	const buttons = [
		{
			text: 'Manage Templates',
			fn: () => history.push('/workout-templates'),
			Icon: SettingsIcon,
		},
		{
			text: 'Settings',
			fn: () => history.push('/settings'),
			Icon: SettingsIcon,
		}
	]

	if ( installable ) {
		buttons.push({
			text: 'Install App',
			fn: install,
			Icon: GetAppIcon,
		})
	}
	if ( user ) {
		buttons.push({
			text: 'Log out',
			fn: signOut,
			Icon: ExitToAppIcon,
		})
	}

	return [
		<AppBar key="appbar-1" position="static">
			<Toolbar variant="dense">
				<IconButton edge="start" color="inherit" aria-label="menu">
					<MenuIcon onClick={() => setOpen(true)} />
				</IconButton>
				<Link className={classes.title} to="/">
					<Typography variant="h6" color="inherit">
						BeGooder - Workout
					</Typography>
				</Link>
				{userStuff}
			</Toolbar>
		</AppBar>,
		<Drawer
			key="appbar-2"
			className={classes.drawer}
			variant="temporary"
			anchor="left"
			open={open}
			classes={{
				paper: classes.drawerPaper,
			}}
			onClose={() => setOpen(false)}
		>
			<div className={classes.drawerHeader}>
				<IconButton onClick={() => setOpen(false)}>
					{theme.direction === 'ltr' ? (
						<ChevronLeftIcon />
					) : (
						<ChevronRightIcon />
					)}
				</IconButton>
			</div>
			<Divider />
			<div style={{ padding: '10px' }}>
			<Typography variant="h6" color="inherit">
				{user ? 'Hi, ' + user.displayName : null}
			</Typography>
			</div>
			<Divider />
			<List>
				{buttons.map(( { text, fn, Icon }, index) => (
					<ListItem button key={text} onClick={ fn }>
						<ListItemIcon>
							<Icon />
						</ListItemIcon>
						<ListItemText primary={text} />
					</ListItem>
				))}
			</List>
			<Divider />
		</Drawer>,
		accountMenu,
	]
}

export default IamAComponent
