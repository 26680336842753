import React from 'react';

import { BrowserRouter as Router, Switch, Route } from 'react-router-dom'
import { ThemeProvider, Container, Paper, Box } from '@material-ui/core'

import theme from './theme'
import WorkoutNew from 'Modules/WorkoutNew'
import WorkoutEdit from 'pages/WorkoutEdit'
import WorkoutTemplates from 'pages/WorkoutTemplates'
import Menu from 'Modules/Menu'
import { Provider as MainProvider } from 'State/app'
import SaveEditing from 'Cogs/SaveEditing'
import Load from 'Cogs/Load'
import { useAuthState } from 'react-firebase-hooks/auth'
import firebase from 'firebase'

import movements from 'Assets/data/movements'

import Home from 'pages/Home'
import Unauthenticated from 'pages/Unauthenticated'

import 'Assets/style/index.scss'

const App = () => {
    const [user] = useAuthState(firebase.auth())

    if ( ! user ) {
        return (
            <ThemeProvider theme={theme}>
                <Box pt={3}>
                    <Container maxWidth="sm" p={0}>
                        <Unauthenticated />
                    </Container>
                </Box>
            </ThemeProvider>
        )
    }

    return (
        <React.StrictMode>
            <Router>
                <Switch>
                    <Route path="/workout/edit/:workoutID">
                        <Compose Component={ WorkoutEdit } />
                    </Route>
                    <Route path="/workout-templates">
                        <Compose Component={ WorkoutTemplates } />
                    </Route>
                    <Route path="/">
                        <Compose Component={ Home } />
                    </Route>
                </Switch>
            </Router>
        </React.StrictMode>
    )
}

const Compose = ({ Component }) => (
    <MainProvider>
        <Load>
            <ThemeProvider theme={theme}>
                <Menu />
                <Box pt={3}>
                    <Container maxWidth="sm" p={0}>
                        <Component />
                    </Container>
                </Box>
            </ThemeProvider>
        </Load>
    </MainProvider>
)

export default App
