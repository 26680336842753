import React from 'react'

// Input-Text
const InputText = (props: InputTextProps) => {
	const { value, onChange } = props

	return (
		<div
			className="input-text"
		>
			<input
				value={ value }
				onChange={ e => onChange( e.target.value ) }
			/>
		</div>
	)
}

interface InputTextProps {
	value: string
	onChange: (x: string) => void
}

export default InputText
