import React, { useState } from 'react'
import {
	Button,
	Select,
	InputLabel,
	MenuItem,
	Box,
	Paper,
	FormControl,
	Typography,
	Divider,
} from '@material-ui/core'
import { useResource, createResource } from 'Util/suspense'

import InputNumber from 'Widgets/InputNumber'
import movements from 'Assets/data/movements'
import firebase from 'firebase/app'
import 'firebase/firestore'
import { Exercise, ExerciseSet, getExercise, getExerciseSet } from 'Types'


const Temp = () => <div>TEMP</div>

const Wrapper = ({ onAdd }) => (
	<React.Suspense fallback={<Temp />}>
		<AddExercise onAdd={onAdd} />
	</React.Suspense>
)

// const makeABetterMe = createResource(getMarker())

const getSets = count => Array.from( { length: count }, () => getExerciseSet() )

const AddExercise = ({ onAdd }) => {
	const [state, setState] = useState<Exercise>( getExercise() )
	const update = (o) => setState({ ...state, ...o })
	const changeSetCount = (count) => {
		if (count < state.sets.length) {
			update({ sets: state.sets.slice(0, count || 1) })
		} else if (count > state.sets.length) {
			update({
				sets: state.sets.concat(
					getSets( count - state.sets.length )
				)
			})
		}
	}

	const updateTargetSet = ( key: string, value: number ) => {
		setState({
			...state,
			targetSets: {
				...state.targetSets,
				[key]: value
			},
			sets: state.sets.map( set => ({
				...set,
				[key]: value
			}))
		})
	}

	return (
		<Paper>
			<Box p={3} mb={3}>
				<div className="add-exercise">
					<Typography>Add Exercise</Typography>

					<Divider />

					<Box mt={3}>
						<FormControl style={{ width: '100%' }}>
							<InputLabel id="demo-simple-select-label">
								Movement
							</InputLabel>
							<Select
								labelId="demo-simple-select-label"
								id="demo-simple-select"
								value={state.movementID}
								onChange={(e) =>
									update({ movementID: e.target.value })
								}
							>
								{movements.map(({ id, name }) => (
									<MenuItem key={id} value={id}>
										{name}
									</MenuItem>
								))}
							</Select>
						</FormControl>
					</Box>

					<Box mt={3}>
						<InputNumber
							label="Set Count"
							value={state.sets.length}
							onChange={changeSetCount}
							min={ 0 }
							max={ 20 }
						/>
					</Box>

					<Box mt={3}>
						<InputNumber
							label="Target Reps"
							value={state.targetSets.reps}
							onChange={ count => updateTargetSet( 'reps', count )}
							min={ 0 }
							max={ 1000 }
						/>
					</Box>

					<Box mt={3}>
						<InputNumber
							label="Target Weight"
							value={state.targetSets.weight}
							onChange={ count => updateTargetSet( 'weight', count )}
							min={ -300 }
							max={ 2000 }
						/>
					</Box>

					<Box mt={3}>
						<Button
							onClick={() =>
								onAdd(state)
							}
						>
							Add
						</Button>
					</Box>
				</div>
			</Box>
		</Paper>
	)
}

export default AddExercise
