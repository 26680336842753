import firebase from 'firebase/app'
import 'firebase/auth'
import 'firebase/firestore'

const firebaseConfig = {
    apiKey: 'AIzaSyDO0LbfKnnNmimGTX1M4HqQeJi9oO0_aMs',
    authDomain: 'dogooder-32bf6.firebaseapp.com',
    projectId: 'dogooder-32bf6',
    storageBucket: 'dogooder-32bf6.appspot.com',
    messagingSenderId: '123743637570',
    appId: '1:123743637570:web:504ea9b4928562313e1ddb',
    measurementId: 'G-24VJZKH6N8',
}
firebase.initializeApp(firebaseConfig)
