
//////////////////////
// WORKOUT TEMPLATE //
//////////////////////

export interface WorkoutTemplate {
	id: string
	title: string
	exercises: Exercise[]
}

/////////////////////
// WORKOUT SESSION //
/////////////////////

export interface WorkoutSession {
	id: string
	timestamp: number
	exercises: Exercise[]
	length: number
}

//////////////
// Exercise //
//////////////

export type ExerciseType = 'standard'

export interface Exercise {
	type: ExerciseType
	movementID: null | number
	sets: Array<ExerciseSet>
	targetSets: ExerciseSet
	notes: string
}
export const getExercise = (): Exercise => ({
	type: <const>'standard',
	movementID: null,
	sets: Array.from( { length: 3 }, () => getExerciseSet() ),
	targetSets: getExerciseSet( 8 ),
	notes: '',
})

//////////////////
// Exercise Set //
//////////////////

export type ExerciseSetType = 'standard'

export interface ExerciseSet {
	type: ExerciseSetType
	reps: null | number
	weight: null | number
}
export const getExerciseSet = ( reps = null, weight = null ): ExerciseSet => ({
	type: <const>'standard',
	reps,
	weight,
})
