
import firebase from 'firebase/app'
import 'firebase/performance'
import 'firebase/analytics'

export const db = firebase.firestore()

const getIdShard = id => id.split('-')[0]

const perf = firebase.performance()
const analytics = firebase.analytics()

db.enablePersistence({ synchronizeTabs: true })


/*
look into thesee
*/
const lazySignIn = auth => auth.signInAnonymously()
const linkAccount = (auth, provider) => auth.currentUser.linkWithPopup(new firebase.auth[ provider ])


////////////
// CREATE //
////////////
export const createWorkout = async (uid, data) => db
		.doc(`/user_data/${uid}/workouts/${data.id[0]}`)
		.set({ [data.id]: data }, { merge: true })


//////////
// READ //
//////////
export const getWorkout = async (uid, id) => {
	const result = await db.doc(`/user_data/${uid}/workouts/${id[0]}`).get()

	return result.data()[id]
}

export const getUserWorkoutCollection = async (uid) => {
	const result = await db
		.collection(`/user_data/${uid}/workouts/`)
		.get()

	// const workouts = []

	// result.docs.forEach( doc => {
	// console.log(Object.values(  ))
	// 	workouts.push( ...Object.values( doc.data() ) )
	// })

	// workouts.sort( ( a, b ) => b.timestamp - a.timestamp )

	return result.docs.map( doc => doc.data() )
}

export const getUserWorkoutTemplates = async (uid) => {
	const result = await db
		.collection(`/user_data/${uid}/workout-templates/`)
		.get()

	return result.docs.map( doc => doc.data() )
}

export const getUserWorkoutCollectionByTime = async (uid) => {
	const result = await db
		.collection(`/user_data/${uid}/workouts/`)
		.get()

	const workouts = []

	result.docs.forEach( doc => {
	console.log(Object.values( doc.data() ))
		workouts.push( ...Object.values( doc.data() ) )
	})

	workouts.sort( ( a, b ) => b.timestamp - a.timestamp )

	return workouts
}


////////////
// UPDATE //
////////////
export const setWorkout = async (uid, data) => db
		.doc(`/user_data/${uid}/workouts/${getIdShard( data.id )}`)
		.set({ [data.id]: data }, { merge: true })

export const setWorkoutShard = async (uid, shard, data) => db
		.doc(`/user_data/${uid}/workouts/${shard}`)
		.set(data, { merge: true })


export const setWorkoutShards = async (uid, data) => {
	var batch = db.batch()

	Object.entries( data ).forEach( ([ shard, workouts ]) => {
		var shardRef = db.doc(`/user_data/${uid}/workouts/${shard}`)
		batch.set( shardRef, workouts, { merge: true } )
	})

	return await batch.commit()
}

/*
Object.entries( state.workouts ).forEach( ([ id, workout ]) => {
	if ( ! workoutUpdates[ getIdShard( id ) ] ) workoutUpdates[ getIdShard( id ) ] = {}
	workoutUpdates[ getIdShard( id ) ][ id ] = workout
})
*/


////////////
// DELETE //
////////////
export const deleteWorkout = async (uid, id) => db
		.doc(`/user_data/${uid}/workouts/${id[0]}`)
		.set({ [id]: firebase.firestore.FieldValue.delete() }, { merge: true })






























// export const getUserWorkoutCollection = async (uid) => {
// 	const result = await db
// 		.collection(`/user_data/${uid}/workouts/`)
// 		.get()

// 	console.log(result)

// 	const xx = result.docs.map( d => d.data() )

// 	console.log(xx)

// 	return xx
// }

// export const getUserExerciseCollection = async (uid) => db
// 		.collection(`/user_data/${uid}/exercises`)
// 		.get()

// export const getExercise = async (uid, id) => db
// 		.doc(`/user_data/${uid}/exercises/${id}`)
// 		.get()

// ////////////
// // UPDATE //
// ////////////
// export const setWorkout = async (uid, data) => db
// 		.doc(`/user_workouts/${uid}`)
// 		.update({ [data.id]: data })

// ////////////
// // STREAM //
// ////////////
// export const streamWorkout = async (uid, data) => db
// 		.doc(`/user_workouts/${uid}`)
// 		.update({ [data.id]: data })

// export const streamExercises = async (uid, data) => db
// 		.doc(`/user_workouts/${uid}`)
// 		.update({ [data.id]: data })

