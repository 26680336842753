import React from 'react'
import { useHistory } from 'react-router-dom'
import { useStore as useAppStore } from 'State/app'
import {
	Button,
	Select,
	InputLabel,
	MenuItem,
	Card,
	CardHeader,
	Avatar,
	CardContent,
	CardActions,
	Box,
	Typography,
	IconButton,
	Collapse,
	Paper,
	Fab,
	FormControl,
	Modal,
	Zoom,
	Container,
	Grid,
	makeStyles,
	Menu,
} from '@material-ui/core'
import FavoriteIcon from '@material-ui/icons/Favorite'
import ShareIcon from '@material-ui/icons/Share'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import MoreVertIcon from '@material-ui/icons/MoreVert'
import AddIcon from '@material-ui/icons/Add'

import movements from 'Assets/data/movements'
import AlGore from 'Assets/img/Al-gore.png'
import SimpleSet from 'Cogs/SimpleSet'
import {createResource} from 'Util/suspense'
import { getUserWorkoutTemplates } from 'Util/db'
import firebase from 'firebase'
import { useAuthState } from 'react-firebase-hooks/auth'
import Loading from 'Widgets/Loading'
import { WorkoutTemplate } from 'Types'

const WorkoutTemplates = () => {
	const [user] = useAuthState(firebase.auth())
	const [state, dispatch] = useAppStore()
	const [ loading, setLoading ] = React.useState( true )
	const [ templates, setTemplates ] = React.useState( [] )

	React.useEffect(() => {
		if ( ! user ) return

		const doit = async () => {
			let userUpToDate = false

			if ( ! userUpToDate ) {
			    const data = await getUserWorkoutTemplates( user.uid )

			    setTemplates( data )
			}

			setLoading( false )
		}

		setLoading( true )
		doit()
	}, [ user ] )


	return loading
		? <Loading />
		: (
			<div className="workout-history">
				<Templates
					templates={ templates }
					user={ user }
					appStore={[state, dispatch]}
				/>
			</div>
		)
}

interface TemplatesProps {
	templates: WorkoutTemplate[]
	user: any
	appStore: any
}
const Templates = ({ user, appStore, templates }) => {
	const [state, dispatch] = appStore
	const history = useHistory()

	return (
		<div className="workout-entry__exercises">
			yeeeet: { JSON.stringify( templates ) }
		</div>
	)
}

export default WorkoutTemplates
