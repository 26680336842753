import React from 'react'
import { setIn, updateIn, fromJS } from 'immutable'
import { AppState } from 'Types'

const reducer = (state: AppState, action): AppState => {
  const params = state.params

  switch ( action.type ) {
  	case 'params:set':
  		return {
  			...state,
  			params: action.params
  		}
  		break;
  }
}

export default reducer
