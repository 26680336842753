import { useState, useEffect } from 'react'
import logo from 'Assets/img/logo.svg'
import { Link, useHistory } from 'react-router-dom'
import { v4 as uuidv4 } from 'uuid'
import {
	Button,
	Select,
	InputLabel,
	MenuItem,
	Card,
	CardHeader,
	Avatar,
	CardContent,
	CardActions,
	Box,
	Typography,
	IconButton,
	Collapse,
	Paper,
	Fab,
	FormControl,
	Modal,
	Zoom,
	Container,
	Grid,
	makeStyles,
	Menu,
} from '@material-ui/core'
import AddIcon from '@material-ui/icons/Add'

import WorkoutHistory from 'Modules/WorkoutHistory'
import { useStore } from 'State/app'
import './index.css'
import {setWorkout,createWorkout, getUserWorkoutCollection  } from 'Util/db'
import firebase from 'firebase'
import { useAuthState } from 'react-firebase-hooks/auth'

const useStyles = makeStyles((theme) => ({
	fab: {
		position: 'fixed',
		bottom: theme.spacing(2),
		right: theme.spacing(2),
	},
}))

const transitionDuration = {
	enter: 350,
	exit: 350,
}

function App(a, b) {
	const [user] = useAuthState(firebase.auth())
	const [state, dispatch] = useStore()
	const [startWorkout, setStartWorkout] = useState(false)
	const classes = useStyles()
	const history = useHistory()

	console.log('ye',state)

	return (
		<div className="App">

			<div
				onClick={() => setStartWorkout(true)}
				className="-mv20 -p20 -card -fullwidth"
			>
				Start a new workout!
			</div>

			{/* Workout History */}
			<WorkoutHistory />

			{/* New Workout Popup */}
			<Modal
				open={startWorkout}
				onClose={() => setStartWorkout(false)}
				aria-labelledby="simple-modal-title"
				aria-describedby="simple-modal-description"
			>
				<div className="modal__base modal__confirm">
					<Typography>Workout Type: </Typography>
					<Button className="ezrow" onClick={() => startNewWorkout( user.uid, history )}>
						Blank Workout
					</Button>
					<Button className="ezrow" onClick={() => alert('!!TODO!!')}>
						From Template
					</Button>
					<Button className="ezrow" onClick={() => alert('!!TODO!!')}>
						Generate Workout
					</Button>
				</div>
			</Modal>

			{/* New Workout FAB */}
			<Zoom
				in={true}
				timeout={transitionDuration}
				style={{
					transitionDelay: `${transitionDuration.exit}ms`,
				}}
				unmountOnExit
			>
				<Fab
					className={classes.fab}
					onClick={() => setStartWorkout(true)}
					variant="extended"
					color="primary"
					aria-label="add"
				>
					<AddIcon />
					Start New Workout
				</Fab>
			</Zoom>

		</div>
	)
}

const startNewWorkout = async ( uid, history ) => {
	const id = '0-' + uuidv4()

	await createWorkout(uid,{
			id,
			timestamp: firebase.firestore.Timestamp.now().seconds*1000,
			exercises: [],
			length: false,
		})

	history.push( '/workout/edit/' + id )
}

export default App
