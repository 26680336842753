import React, { useState } from 'react'
import { useParams, useHistory } from 'react-router-dom'
import {
	Button,
	Select,
	InputLabel,
	MenuItem,
	Card,
	CardHeader,
	Avatar,
	CardContent,
	CardActions,
	Box,
	Typography,
	IconButton,
	Collapse,
	Paper,
	Fab,
	FormControl,
	Modal,
	Zoom,
	Container,
	Grid,
	makeStyles,
	Menu,
} from '@material-ui/core'
import FavoriteIcon from '@material-ui/icons/Favorite'
import ShareIcon from '@material-ui/icons/Share'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import MoreVertIcon from '@material-ui/icons/MoreVert'
import AddIcon from '@material-ui/icons/Add'

import movements from 'Assets/data/movements'
import AddExercise from 'Cogs/AddExercise'
import SimpleSet from 'Cogs/SimpleSet'
import { useStore } from 'State/app'
import {getWorkout} from 'Util/db'
import {createResource} from 'Util/suspense'
import {setWorkout, getUserWorkoutCollection  } from 'Util/db'
import firebase from 'firebase'
import { useAuthState } from 'react-firebase-hooks/auth'
import InputNumber from 'Widgets/InputNumber'
import InputText from 'Widgets/InputText'

const useStyles = makeStyles((theme) => ({
	fab: {
		position: 'fixed',
		bottom: theme.spacing(2),
		right: theme.spacing(2),
	},
}))

const standardWorkoutSetDefaults = { reps: null }

/*

new workout
- add new uuid to `workouts`
- set current workout and redirect

add exercise
- if ! in exercises, create new
- create uuid, add to workout obj

save
- batch workouts && exercises to db

*/

const Timer = ({ startTime }: any) => {
	const [ time, setTime ] = React.useState( 0 )

	React.useEffect( () => {
		setTimeout( () => setTime( Math.floor( (Date.now() - startTime) / 1000 ) ), 1000 )
	}, [ time ] )

	return (
		<div className="timer-container">
			{ time }
		</div>
	)
}

const WorkoutEdit = ( {resource} ) => {
	const [user] = useAuthState(firebase.auth())
	let { workoutID } = useParams<any>()
	let history = useHistory()
	const [state, dispatch] = useStore()
	const [ start, setStart ] = React.useState( Date.now() )

	const workout = state.workouts[ workoutID ]

	// const workout = state.workouts[workoutID]
	const classes = useStyles()
	const [openAddNew, setOpenAddNew] = useState(false)
	const onAddExercise = (exercise) => {
		dispatch({
			type: 'addExercise',
			payload: {
				workoutID,
				exercise,
			},
		})
	}
	const endWorkout = () => {
		dispatch({
			type: 'endWorkout',
			payload: workoutID,
		})

		history.push('/')
	}

	const transitionDuration = {
		enter: 350,
		exit: 350,
	}

	if (! workout) return null

	return (
		<div id="workout-new">

			<Timer startTime={ start } />

			<div
				onClick={() => setOpenAddNew(true)}
				className="-mv20 -p20 -card -fullwidth"
			>
				Add Exercise!
			</div>

			{workout.exercises.map((exercise, i) => {
				return (
					<Exercise
						setStart={ setStart }
						exercise={ exercise }
						key={'workout-exercises-' + i + '-' + workoutID}
						dispatch={ dispatch }
						exerciseIndex={ i }
						updateExercise={(update) => {
							dispatch({
								type: 'updateWorkoutExercise',
								payload: {
									workoutID,
									exerciseIndex: i,
									exercise: { ...exercise, ...update },
								},
							})
						}}
						updateSets={(newSets) =>{
							dispatch({
								type: 'updateWorkoutSets',
								payload: {
									workoutID,
									exerciseIndex: i,
									newSets,
								},
							})
						}}
						deleteSet={() => {
							dispatch({
								type: 'deleteWorkoutExercise',
								payload: { workoutID, exerciseIndex: i },
							})
						}}
					/>
				)
			})}

			<Button onClick={endWorkout}>End Workout</Button>

			<Modal
				open={openAddNew}
				onClose={() => setOpenAddNew(false)}
				aria-labelledby="simple-modal-title"
				aria-describedby="simple-modal-description"
			>
				<Container className="layout__center" maxWidth="sm">
					<AddExercise onAdd={onAddExercise} />
				</Container>
			</Modal>

			<Zoom
				in={true}
				timeout={transitionDuration}
				style={{
					transitionDelay: `${transitionDuration.exit}ms`,
				}}
				unmountOnExit
			>
				<Fab
					className={classes.fab}
					onClick={() => setOpenAddNew(true)}
					variant="extended"
					color="primary"
					aria-label="add"
				>
					<AddIcon />
					Add Exercise
				</Fab>
			</Zoom>
		</div>
	)
}

const Exercise = ({ setStart, exercise, exerciseIndex, dispatch, updateSets, updateExercise, deleteSet }) => {
	const { sets, movementID, targetSets } = exercise
	const [editSetCount, seteditSetCount] = React.useState(false)
	const [anchorEl, setAnchorEl] = React.useState(null)
	const onRepClick = (index) => (reps) => {
		let newSets = [...sets]

		newSets[index] = { ...sets[index], reps: reps }

		updateSets(newSets)
		setStart( Date.now() )
	}
	const changeSetCount = (count) => {
		if (count < sets.length) {
			updateSets(sets.slice(0, count || 1))
		} else if (count > sets.length) {
			updateSets(
				sets.concat(
					Array.from(
						{ length: count - sets.length },
						() => standardWorkoutSetDefaults
					)
				)
			)
		}
	}
	const changeTargetSet = ( key, value ) => {
		updateExercise({
			targetSets: {
				...targetSets,
				[key]: value
			}
		})
	}

	const handleClick = (event) => {
		setAnchorEl(event.currentTarget)
	}

	const handleClose = () => {
		setAnchorEl(null)
	}

	const options = {
		'Edit Exerxcise': () => {
			seteditSetCount(true)
			handleClose()
		},
		'Clear reps': () => {
			dispatch({ type: 'workout:clearSet', exerciseIndex })
			handleClose()
		},
		Delete: () => {
			deleteSet()
			handleClose()
		},
	}

	return (
		<Box mb={3}>
			<Card>
				<CardHeader
					className="card-header-underline"
					action={
						<div>
							<IconButton
								aria-label="more"
								aria-controls="long-menu"
								aria-haspopup="true"
								onClick={handleClick}
							>
								<MoreVertIcon />
							</IconButton>
							<Menu
								id="long-menu"
								anchorEl={anchorEl}
								open={Boolean(anchorEl)}
								onClose={handleClose}
							>
								{Object.entries(options).map(
									([text, action]) => (
										<MenuItem
											key={
												'workout-menu-' +
												text
											}
											onClick={action}
										>
											{text}
										</MenuItem>
									)
								)}
							</Menu>
						</div>
					}
					title={
						movements.find(({ id }) => id === movementID).name
					}
					subheader={
						<div>
							{ targetSets.weight && <p>{`Weight: ${targetSets.weight} lbs.`}</p> }
							{ exercise.notes && <p>{ exercise.notes }</p>}
						</div>
					}
				/>
				<CardContent>
					<div className="set-list">
						{sets.map(({ reps }, i) => (
							<SimpleSet
								key={i}
								targetSets={targetSets}
								reps={reps}
								update={onRepClick(i)}
							/>
						))}
					</div>
				</CardContent>
			</Card>
			<Modal open={editSetCount} onClose={() => seteditSetCount(false)}>
				<div className="modal__base modal__confirm">
					<InputNumber
						label="Edit Set Count"
						onChange={ changeSetCount }
						value={ sets.length }
						min={ 1 }
						max={ Infinity }
					/>

					<hr className="-mv20" />

					<InputNumber
						label="Edit Target Reps"
						onChange={ val => changeTargetSet( 'reps', val ) }
						value={ targetSets.reps }
						min={ 1 }
						max={ Infinity }
					/>

					<hr className="-mv20" />

					<InputNumber
						label="Edit Target Weight"
						onChange={ val => changeTargetSet( 'weight', val ) }
						value={ targetSets.weight }
						min={ 0 }
						max={ Infinity }
					/>

					<hr className="-mv20" />

					<InputText
						onChange={ val => updateExercise({ notes: val }) }
						value={ exercise.notes }
					/>
				</div>
			</Modal>
		</Box>
	)
}

export default WorkoutEdit
